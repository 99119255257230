import React from "react"
import { Container, Section } from "../utils/utils"

const Page404 = () => {
  return (
    <Section
      pt="6rem"
      pb="3rem"
      tabIndex="0"
      role="contentinfo"
      aria-label="Strona 404 - podany adres nieistnieje"
    >
      <Container>
        <h1>404</h1>
        <h6>
          <span>Niestety szukana strona nie istnieje</span>
        </h6>
      </Container>
    </Section>
  )
}
export default Page404
